import React, { Component } from 'react';
import me from '../img/me.jpg';
import arcraft from "../img/ARCraftImg1.PNG";
import itower from "../img/iTowerPreview.jpg";
import placeholder from "../img/placeholder.jpg";
import pandadodge from "../img/PandaDodgePreview.jpg";
import drill from "../img/drillPreview.jpg";
import threeKingdoms from "../img/threeKingdomsPreview.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CardDeck from "react-bootstrap/CardDeck"
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Link } from 'react-router-dom';
import style from '../css/Home.module.css';

class Home extends Component{
    render() {
        return <div className={style.contentWrapper}>
                    <section id="home">
                        <div className={style.myJumbotron}>
                            <Col className={style.contentCenter} sm={true}>
                                <Row className={style.contentCenter}>
                                    <div className={style.imageContainer}>
                                        <img className={style.myImage} src={ me } alt="Licheng"/>
                                    </div>
                                </Row>
                                <Row className={style.contentCenter}>
                                    <h1 className={style.nameText}>Licheng Jiang</h1>
                                </Row>
                                <Row className={style.contentCenter}>
                                    <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A passionate gamer and a software engineer.</p>
                                </Row>
                            </Col>
                            <hr className={style.horizontal}/>
                        </div>
                    </section>

                    <section id="portfolio">
                        <div className={style.portfolioTitleWrapper}>
                            <h1 className={style.nameText}>Portfolio</h1>
                            <hr className={style.horizontal}/>
                        </div>
                        <div className={style.deckContainer1}>
                            <CardDeck>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/arcraft">
                                        <Card.Img variant="top" src={arcraft} />
                                        <Card.Body>
                                            <Card.Title>ARCraft</Card.Title>
                                            <Card.Text>
                                                A AR mobile game programmed with Unity. Players need to build target objects
                                                with given blocks in real scene. In custom mod, players can build custom world
                                                with blocks.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/itower">
                                        <Card.Img variant="top" src={itower} />
                                        <Card.Body>
                                            <Card.Title>iTower</Card.Title>
                                            <Card.Text>
                                                A Unity tower defense game cloned from a github repository. Modifications were
                                                made to train the game agent.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/pandadodge">
                                        <Card.Img variant="top" src={pandadodge} />
                                        <Card.Body>
                                            <Card.Title>PandaDodge</Card.Title>
                                            <Card.Text>
                                                A mobile game programmed in Unity. Players need to evade falling rocks
                                                and collect items.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                            </CardDeck>
                        </div>

                        <div className={style.deckContainer2}>
                            <CardDeck>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/gamifiedsimulator">
                                        <Card.Img variant="top" src={drill} />
                                        <Card.Body>
                                            <Card.Title>Gamified Engineering Simulator</Card.Title>
                                            <Card.Text>
                                                A 3D gamified simulator simulates using handsaw and drill in Unity.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/rollercoastertrack">
                                        <Card.Img variant="top" src={placeholder} />
                                        <Card.Body>
                                            <Card.Title>Roller Coaster Track</Card.Title>
                                            <Card.Text>
                                                My first 3D graphics project programmed with C++. OpenGL was implemented.
                                                Scene consists with skybox, heightmap terrain and a roller coaster track.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                                <Card className={style.portfolioCard}>
                                    <div className={style.portfolioContentWrapper}>
                                        <Link className={style.portfolioContentLink} to="/portfolio/threekingdoms">
                                        <Card.Img variant="top" src={threeKingdoms} />
                                        <Card.Body>
                                            <Card.Title>Three Kingdoms Chapter 1 Scene</Card.Title>
                                            <Card.Text>
                                                My first game like application programmed with C#. Scenes present content
                                                of chapter 1 in the novel where brotherhood of three main characters begins.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </div>
                                </Card>
                            </CardDeck>
                        </div>
                        <hr style={{marginTop:"10rem"}} />
                    </section>

                    <section id="background">
                        <div className={style.backgroundTitleWrapper}>
                            <h1 className={style.nameText}>Background</h1>
                            <hr className={style.horizontal}/>
                        </div>
                        <div className={style.backgroundContainer}>
                            <Row>
                                <Col className={style.educationHeader} sm={2}>
                                    <h4 className={style.educationHeaderText}>Career</h4>
                                </Col>
                                <Col sm={1}></Col>
                                <Col sm={9}>
                                    <Row>
                                        <Table responsive>
                                            <thead>
                                            <tr>
                                                <th>Position</th>
                                                <th>Company</th>
                                                <th>Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Software Engineer</td>
                                                <td>Humana</td>
                                                <td>Mar. 2021 - Present</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.educationSecondSection}>
                                <Col className={style.educationHeader} sm={2}>
                                    <h4 className={style.educationHeaderText}>Education</h4>
                                </Col>
                                <Col sm={1}></Col>
                                <Col sm={9}>
                                    <Row>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Degree</th>
                                                    <th>Institution</th>
                                                    <th>Graduation Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>M.S. in Computer Science</td>
                                                    <td>University of Southern California</td>
                                                    <td>Dec., 2020</td>
                                                </tr>
                                                <tr>
                                                    <td>B.S. in Computer Science</td>
                                                    <td>Pennsylvania State University</td>
                                                    <td>May, 2018</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.educationSecondSection}>
                                <Col className={style.educationHeader} sm={2}>
                                    <h4 className={style.educationHeaderText}>Skills</h4>
                                </Col>
                                <Col sm={1}></Col>
                                <Col sm={9}>
                                    <Row>
                                        <Table responsive borderless={true}>
                                            <thead>
                                            <tr>
                                                <th>Programming Languages</th>
                                                <th>Web Related</th>
                                                <th>Engine/Frameworks</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Python</td>
                                                    <td>PHP</td>
                                                    <td>Unity</td>
                                                </tr>
                                                <tr>
                                                    <td>C++</td>
                                                    <td>JavaScript</td>
                                                    <td>Angular</td>
                                                </tr>
                                                <tr>
                                                    <td>C</td>
                                                    <td>CSS</td>
                                                    <td>React</td>
                                                </tr>
                                                <tr>
                                                    <td>C#</td>
                                                </tr>
                                                <tr>
                                                    <td>Java</td>
                                                </tr>
                                                <tr>
                                                    <td>Swift</td>
                                                </tr>
                                                <tr>
                                                    <td>Kotlin</td>
                                                </tr>
                                                <tr>
                                                    <td>Scala</td>
                                                </tr>
                                                <tr>
                                                    <td>SQL</td>
                                                </tr>
                                                <tr>
                                                    <td>Terraform</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.educationThirdSection}>
                                <Col className={style.educationHeader} sm={2}>
                                    <h4 className={style.educationHeaderText}>About Me</h4>
                                </Col>
                                <Col sm={1}></Col>
                                <Col className=" col-lg-7 col-md-7 col-sm-7 col-xs-12 pull-left" sm={9}>
                                    <div className="lead"><span className={style.greatingText}>Hi there!</span> My name is Licheng Jiang. I am passionate
                                        gamer. I have PS4 console and Nintendo Switch in my home, and I also own over two
                                        hundred games in my steam library. Through years of game experience and browsing
                                        game store page, I find there is no game perfectly fit my interest, so I dream of
                                        releasing my own game in the future. My academic projects in USC
                                        and my previous work experience as research assistant provide me solid knowledge
                                        background in game design idea, game programming, game mechanics. My professional experience as software
                                        engineer provides me ability to work in real production environment. I work in pair with my colleagues in
                                        agile development pattern, which makes me feel comfortable to work as a team.
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>;
    }
}

export default Home;