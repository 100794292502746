import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import style from '../css/MyNavbar.module.css';
import {NavHashLink as Link} from "react-router-hash-link";
//import {Link} from 'react-scroll';

function MyNavbar(){
    return(
        <Navbar className={style.navWrap} fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand className={style.brandFont} href="/#home">Licheng Jiang</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={style.myNavLinksWrapper}>
                    <Link className={style.myNavLinksRight} to="/#home">Home</Link>
                    <Link className={style.myNavLinksRight} to="/#portfolio">Portfolio</Link>
                    <Link className={style.myNavLinksRight} to="/#background">Background</Link>
                </Nav>
                <Nav>
                    <Nav.Link className={style.myNavLinksLeft} href="https://www.linkedin.com/in/licheng-jiang-3a7993136/">LinkedIn</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default MyNavbar;