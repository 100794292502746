import React, { Component } from 'react';
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import image1 from "../img/ARCraftImg1.PNG";
import image2 from "../img/ARCraftImg2.PNG";
import image3 from "../img/ARCraftImg3.PNG";
import image4 from "../img/ARCraftImg4.PNG";
import image5 from "../img/ARCraftImg5.PNG";
import image6 from "../img/ARCraftImg6.png";
import Lightbox from "react-lightbox-component";
import "react-lightbox-component/build/css/index.css";


class ARCraft extends Component{
    render() {
        let images=[
            {
                src:image1,
                title:"Custom Build",
                description: "Player can build custom scenes"
            },
            {
                src:image2,
                title:"Scan Platform",
                description: "Scan the basement to place blocks"
            },
            {
                src:image3,
                title:"Place Block",
                description: "After placing a block"
            },
            {
                src:image4,
                title:"Place Block",
                description: "Select what block to place"
            },
            {
                src: image5,
                title: "Block Menu",
                description: "Select any type block player wants"
            },
            {
                src: image6,
                title: "Custom Build",
                description: "Even build a ship!"
            }
        ]
        return <div className={style.contentWrapper}>
                <div className={style.myJumbotron}>
                    <Col className={style.contentCenter} sm={true}>
                        <Row className={style.contentCenter}>
                            <h1 className={style.nameText}>ARCraft</h1>
                        </Row>
                        <Row className={style.contentCenter}>
                            <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A iOS AR puzzle like game (Ongoing)</p>
                        </Row>
                    </Col>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.videowrapper}>
                    <div>
                        <h1 className={style.nameText}>Demo Video</h1>
                        <hr className={style.horizontal}/>
                    </div>
                    <div className={style.videoContainer}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/0Ej08s-BY5A"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div className={style.detailTextContainer}>
                    <div>
                        <h1 className={style.nameText}>Project Detail</h1>
                        <hr className={style.horizontal}/>
                    </div>
                    <p className={style.detailText}>[Group Project] I led the development of this game with Unity engine.
                        Players are required to scan an empty plane with phone camera to generate a work surface. Players then
                        can place blocks over the grid based work space. Selected a block will be shown in center of camera,
                        and players need to move camera around to adjust position of the block.
                    </p>
                    <p className={style.detailText}>
                        In level mod, a three-view drawing of a specific object will be shown to players in each level,
                        and players are required to construct the target object based on the three-view drawing with a
                        given amount of blocks. Player’s construction operation will be conducted on a highlighted
                        platform in the actual camera scene. Once the remaining block number becomes 0, our game will
                        evaluate players’ work. Players’ target is to challenge higher levels with more complicated
                        target objects.
                    </p>
                    <p className={style.detailText}>
                        In custom mod, players can use what ever blocks as they wish to build their own fantasy world.
                    </p>
                    <div className={style.lightBox}>
                        <Lightbox
                            images={images}
                            showImageModifiers={false}
                            thumbnailWidth='30vw'
                            thumbnailHeight='auto'
                            renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                                return (
                                    <img
                                        key={idx}
                                        src={image.src}
                                        className={style.test}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                )
                            }}
                        />
                    </div>
                </div>
            </div>;
    }
}

export default ARCraft;