import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import MyNavbar from "./components/layouts/MyNavbar";
//React Router Import
import { Switch, Route} from 'react-router-dom';
import Home from "./components/Pages/Home";
import ARCraft from "./components/Pages/ARCraft";
import iTower from "./components/Pages/iTower";
import PandaDodge from "./components/Pages/PandaDodge";
import GamifiedEngineeringSimulator from "./components/Pages/GamifiedEngineeringSimulator";
import RollerCoasterTrack from "./components/Pages/RollerCoasterTrack";
import ThreeKingdomsScene from "./components/Pages/ThreeKingdomsScene";
import PageNotFound from "./components/Pages/PageNotFound";

function App() {
  return (
    <div className='wrap'>
      <MyNavbar/>
      <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/portfolio/arcraft" component={ARCraft}/>
          <Route path="/portfolio/itower" component={iTower}/>
          <Route path="/portfolio/pandadodge" component={PandaDodge}/>
          <Route path="/portfolio/gamifiedsimulator" component={GamifiedEngineeringSimulator}/>
          <Route path="/portfolio/rollercoastertrack" component={RollerCoasterTrack}/>
          <Route path="/portfolio/threekingdoms" component={ThreeKingdomsScene}/>
          <Route component={PageNotFound}/>
      </Switch>
    </div>
  );
}

export default App;
