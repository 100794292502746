import React, { Component } from 'react';
import image1 from "../img/iTowerImg1.jpg";
import image2 from "../img/iTowerImg2.jpg";
import image3 from "../img/iTowerImg3.jpg";
import image4 from "../img/iTowerImg4.jpg";
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Lightbox from "react-lightbox-component";

class PageNotFound extends Component{
    render() {
        return <div className={style.contentWrapper}>
            <div className={style.myJumbotron}>
                <Col className={style.contentCenter} sm={true}>
                    <Row className={style.contentCenter}>
                        <h1 className={style.nameText}>404</h1>
                    </Row>
                    <Row className={style.contentCenter}>
                        <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>Page Not Found</p>
                    </Row>
                </Col>
                <hr className={style.horizontal}/>
            </div>
        </div>;
    }
}

export default PageNotFound;