import React, { Component } from 'react';
import image1 from "../img/SimImg1.jpg";
import image2 from "../img/SimImg2.jpg";
import image3 from "../img/SimImg3.jpg";
import image4 from "../img/SimImg4.jpg";
import image5 from "../img/falcon.JPG";
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Lightbox from "react-lightbox-component";

class GamifiedEngineeringSimulator extends Component{
    render() {
        let images=[
            {
                src:image2,
                title:"Drill Simulator Start Menu",
                description: "Different game features can be selected"
            },
            {
                src:image1,
                title:"Drill Simulator Scene",
                description: "Position drill to target area"
            },
            {
                src:image3,
                title:"Handsaw Simulator Scene",
                description: "Score and error angle are calculated dynamically"
            },
            {
                src:image4,
                title:"Handsaw Simulator Finish Cutting Scene",
                description: "Fragment models are dynamically generated to replace old model"
            },
            {
                src:image5,
                title:"Falcon Haptic Device",
                description: "Provide 3 dimension force feedback"
            }
        ]
        return <div className={style.contentWrapper}>
            <div className={style.myJumbotron}>
                <Col className={style.contentCenter} sm={true}>
                    <Row className={style.contentCenter}>
                        <h1 className={style.nameText}>Gamified Engineering Simulator</h1>
                    </Row>
                    <Row className={style.contentCenter}>
                        <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A gamified training simulator aimed to enhance training effect under engineering environment</p>
                    </Row>
                </Col>
                <hr className={style.horizontal}/>
            </div>
            <div className={style.videowrapper}>
                <div>
                    <h1 className={style.nameText}>Demo Video</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.videoContainer}>
                    <iframe src="https://www.youtube.com/embed/SwEh1Zdux40" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={style.videoContainer}>
                    <iframe src="https://www.youtube.com/embed/WzbtK9QLlKM" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
            <div className={style.detailTextContainer}>
                <div>
                    <h1 className={style.nameText}>Project Detail</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.detailText}> Programmed with Unity engine. Prototype was a handsaw simulator. Wood
                    can be cut into two piece. Fragment models is dynamically generated based on cutting edges. Users are
                    able to choose hardness of wood which can emulate the difficulty of cutting. Due to limited control input
                    directions supported by falcon haptic device, this near complete prototype was discarded and a new drill
                    simulator is developed as replacement. Users will grip the haptic device to simulate gripping a real
                    drill. A reverse force feedback is designed to emulate the weight of drill. Users are able to select
                    game features in simulator, including score, badges and different levels. Users are required to use
                    drill to make hole on highlighted points. Score is calculated by the distance between actual
                    drill holes and target points. When drill model contact with wood model, a vibration simulated by repetitive
                    force feedback is applied to user's hand to simulate the vibration of electric motor. The vibration
                    coefficient can be set to simulate different strength of vibration. Training data will be collected and
                    stored to a local file for research use.
                </div>
                <div className={style.lightBox}>
                    <Lightbox
                        images={images}
                        showImageModifiers={false}
                        thumbnailWidth='30vw'
                        thumbnailHeight='auto'
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                            return (
                                <img
                                    key={idx}
                                    src={image.src}
                                    className={style.test}
                                    onClick={toggleLightbox.bind(null, idx)} />
                            )
                        }}
                    />
                </div>
            </div>
        </div>;
    }
}

export default GamifiedEngineeringSimulator;