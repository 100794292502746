import React, { Component } from 'react';
import image1 from "../img/threeImg1.jpg";
import image2 from "../img/threeImg2.jpg";
import image3 from "../img/threeImg3.jpg";
import image4 from "../img/threeImg4.jpg";
import image5 from "../img/threeImg5.jpg";
import image6 from "../img/threeImg6.jpg";
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Lightbox from "react-lightbox-component";

class ThreeKingdomsScene extends Component{
    render() {
        let images=[
            {
                src:image1,
                title:"Not Close Enough",
                description: "Prompt player to get closer to NPC"
            },
            {
                src:image2,
                title:"Dialogue",
                description: "Classic visual novel dialogue with art"
            },
            {
                src:image3,
                title:"Walk",
                description: "Move to target position with walk animation"
            },
            {
                src:image4,
                title:"Collision Detection",
                description: "Stop main character from walking over building images"
            },
            {
                src:image5,
                title:"Scene Switch",
                description: "Prompt map name. Main character is behind the tree"
            },
            {
                src:image6,
                title:"Dialogue",
                description: "Plot dialogue"
            }
        ]
        return <div className={style.contentWrapper}>
            <div className={style.myJumbotron}>
                <Col className={style.contentCenter} sm={true}>
                    <Row className={style.contentCenter}>
                        <h1 className={style.nameText}>Three Kingdoms Chapter 1 Scene</h1>
                    </Row>
                    <Row className={style.contentCenter}>
                        <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A visual novel game prototype</p>
                    </Row>
                </Col>
                <hr className={style.horizontal}/>
            </div>
            <div className={style.detailTextContainerForPandaDodge}>
                <div>
                    <h1 className={style.nameText}>Project Detail</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.detailText}> Programmed in C# with no engine support. My first game application project.
                    Most arts are self created with Adobe Photoshop. Players can use mouse click to control main character to move.
                    Main character can have referenced dialogues with NPCs. If main character is too far away from NPCs, a
                    prompt will be shown to request player to move closer to NPCs. Scene can be switched. Arts are layered
                    by distance. Pixel collision detection implemented to prevent main character from walking on buildings.
                    Double buffer is implemented to prevent image flickering.
                </div>
                <div className={style.lightBox}>
                    <Lightbox
                        images={images}
                        showImageModifiers={false}
                        thumbnailWidth='30vw'
                        thumbnailHeight='auto'
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                            return (
                                <img
                                    key={idx}
                                    src={image.src}
                                    className={style.test}
                                    onClick={toggleLightbox.bind(null, idx)} />
                            )
                        }}
                    />
                </div>
            </div>
        </div>;
    }
}

export default ThreeKingdomsScene;