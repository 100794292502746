import React, { Component } from 'react';
import image1 from "../img/PanadaDodgeImg1.png";
import image2 from "../img/PanadaDodgeImg2.png";
import image3 from "../img/PanadaDodgeImg3.png";
import image4 from "../img/PanadaDodgeImg4.png";
import image5 from "../img/PanadaDodgeImg5.png";
import image6 from "../img/PanadaDodgeImg6.png";
import image7 from "../img/PanadaDodgeImg7.png";
import image8 from "../img/PanadaDodgeImg8.png";
import image9 from "../img/PanadaDodgeImg9.png";
import image10 from "../img/PanadaDodgeImg10.png";
import image11 from "../img/PanadaDodgeImg11.png";
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Lightbox from "react-lightbox-component";

class PandaDodge extends Component{
    render() {
        let images=[
            {
                src:image1,
                title:"Start Menu",
                description: "Player can choose start game or see collection page"
            },
            {
                src:image2,
                title:"Level Map",
                description: "Choose which level to play"
            },
            {
                src:image7,
                title:"Level 1 Game Scene",
                description: "Evade rocks, collecting target ingredients and coins"
            },
            {
                src:image9,
                title:"Level 2 Game Scene",
                description: "Different ingredients, different harmful falling objects with higher damage, different scene"
            },
            {
                src:image4,
                title:"Refrigerator",
                description: "See ingredients collected in games. Ingredients can be used for cooking"
            },
            {
                src:image6,
                title:"Kitchen",
                description: "Select correct ingredients to cook target dish to unlock next level"
            },
            {
                src:image11,
                title:"Craft Fail",
                description: "Wrong ingredients combination selected, ingredients lost"
            },
            {
                src:image10,
                title:"Craft Success",
                description: "Correct ingredients combination selected, next level unlocked, collection added"
            },
            {
                src:image3,
                title:"Empty Collection Book",
                description: "After successfully cook dish with right ingredients, dish status will appear here"
            },
            {
                src:image8,
                title:"Filled Collection Book",
                description: "Badges for successful craft showed here"
            },
            {
                src:image5,
                title:"Shop",
                description: "Can buy additional life with coins collected"
            }
        ]
        return <div className={style.contentWrapper}>
            <div className={style.myJumbotron}>
                <Col className={style.contentCenter} sm={true}>
                    <Row className={style.contentCenter}>
                        <h1 className={style.nameText}>PandaDodge</h1>
                    </Row>
                    <Row className={style.contentCenter}>
                        <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A iOS game with Chinese cultural elements</p>
                    </Row>
                </Col>
                <hr className={style.horizontal}/>
            </div>
            <div className={style.detailTextContainerForPandaDodge}>
                <div>
                    <h1 className={style.nameText}>Project Detail</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.detailText}>[Group Project] Developed with Unity engine. Player need to control main character (the panda) to evade
                    falling objects and collect target ingredients to finish each level. Once hit points reach 0, game ends.
                    Players can check amount of ingredients in backpack system (the fridge). We also designed craft system
                    for player to cook dishes based on real recipes. Player can unlock next level by cook with correct combination
                    of ingredients collected in game. No matter success or failure, ingredients used will be deducted from fridge.
                    Players can also buy items from store with coins collected in game.
                    <br/>
                    <span className={style.formulaText}>Github source code: <a href="https://github.com/CookedPotato/PandaDodgeCopy">
                        https://github.com/CookedPotato/PandaDodgeCopy</a><br/></span>
                </div>
                <div className={style.lightBox}>
                    <Lightbox
                        images={images}
                        showImageModifiers={false}
                        thumbnailWidth='30vw'
                        thumbnailHeight='auto'
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                            return (
                                <img
                                    key={idx}
                                    src={image.src}
                                    className={style.test}
                                    onClick={toggleLightbox.bind(null, idx)} />
                            )
                        }}
                    />
                </div>
            </div>
        </div>;
    }
}

export default PandaDodge;