import React, { Component } from 'react';
import image1 from "../img/iTowerImg1.jpg";
import image2 from "../img/iTowerImg2.jpg";
import image3 from "../img/iTowerImg3.jpg";
import image4 from "../img/iTowerImg4.jpg";
import style from "../css/Project.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Lightbox from "react-lightbox-component";

class iTower extends Component{
    render() {
        let images=[
            {
                src:image1,
                title:"Agent plays game in multi-threading",
                description: "New wave monster generated"
            },
            {
                src:image2,
                title:"Agent plays game in multi-threading",
                description: "Auto placed towers attack monsters"
            },
            {
                src:image3,
                title:"Agent plays game in multi-threading",
                description: "Half monsters killed"
            },
            {
                src:image4,
                title:"Place Block",
                description: "Wave 10, some hit points lost"
            }
        ]
        return <div className={style.contentWrapper}>
            <div className={style.myJumbotron}>
                <Col className={style.contentCenter} sm={true}>
                    <Row className={style.contentCenter}>
                        <h1 className={style.nameText}>iTower</h1>
                    </Row>
                    <Row className={style.contentCenter}>
                        <p className={style.selfDescriptionText} style={{padding:"0 3rem"}}>A machine learning agent that plays this tower defense game automatically</p>
                    </Row>
                </Col>
                <hr className={style.horizontal}/>
            </div>
            <div className={style.videowrapper}>
                <div>
                    <h1 className={style.nameText}>Demo Video</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.videoContainer}>
                    <iframe src="https://www.youtube.com/embed/IixXsQ-Yrns" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
            <div className={style.detailTextContainer}>
                <div>
                    <h1 className={style.nameText}>Project Detail</h1>
                    <hr className={style.horizontal}/>
                </div>
                <div className={style.detailText}>[Group Project] Original game is a Unity game cloned from Github repository 2D-Tower-Defense,
                    2017, created by <a href=" https://github.com/JessHua159">Jesse Huang</a>. We changed game features including,
                    map path, monster life and speed, monster amount. We created our python agent to automatically use API calls to
                    place towers and sell towers before each wave. In training phase, we lifted money restriction in order to better
                    generate training data. We implemented multi-threading to simultaneously play games and successfully collected
                    3000 tower placement based on random strategies. We built our reward function as:
                    <br/>
                    <span className={style.formulaText}>Reward = current lives left + min distance from monster killed grid to destination gate<br/></span>
                    The design idea is that a better placement strategy can always kill monsters quicker that a worse placement strategy (higher fire
                    power density in average). We initially used CNN model to classify good and bad data. We used regression model to train
                    our agent, because single metric reward is more explicit. After training, our agent's best performance increased from
                    20 waves to 22 waves. And a significant increase in reward of each wave was observed. To see more details:
                    <br/>
                    <span className={style.formulaText}>Github source code: <a href="https://github.com/csci-599-applied-ml-for-games/iTower">
                        https://github.com/csci-599-applied-ml-for-games/iTower</a><br/></span>
                    <span className={style.formulaText}>Project website: <a href="https://infallible-edison-5e9e7a.netlify.app/">
                        https://infallible-edison-5e9e7a.netlify.app/</a><br/></span>
                </div>
                <div className={style.lightBox}>
                    <Lightbox
                        images={images}
                        showImageModifiers={false}
                        thumbnailWidth='30vw'
                        thumbnailHeight='auto'
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                            return (
                                <img
                                    key={idx}
                                    src={image.src}
                                    className={style.test}
                                    onClick={toggleLightbox.bind(null, idx)} />
                            )
                        }}
                    />
                </div>
            </div>
        </div>;
    }
}

export default iTower;